import { createTheme, CssBaseline, Theme, ThemeOptions, ThemeProvider } from "@mui/material";
import React, { createContext, ReactNode, useState } from "react";

export const LIGHT_THEME: ThemeOptions =
{
    shape: {
        borderRadius: 8
    },
    palette: {
        mode: 'light',
        primary: {
            main: "#00ebaa"
        },
        secondary: {
            main: "#5e67df"
        }
    },
};

export const DARK_THEME: ThemeOptions =
{
    shape: {
        borderRadius: 8
    },
    palette: {
        mode: 'dark',
        background: {
            default: 'black'
        },
        primary: {
            main: "#00ebaa",
        },
        secondary: {
            main: "#5e67df"
        }
    },
};

export const ThemeContext = createContext<[Theme | undefined, React.Dispatch<React.SetStateAction<Theme | undefined>>]>([undefined, () => { }]);

type AppThemeProps = {
    children?: ReactNode | undefined;
};
const AppTheme = (props: AppThemeProps) => {
    const { children } = props;
    const [theme, setTheme] = useState<Theme | undefined>(undefined);

    return (
        <ThemeContext.Provider value={[theme, setTheme]}>
            <ThemeProvider theme={theme || createTheme(DARK_THEME)}>
                <CssBaseline enableColorScheme />
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};
export default AppTheme;
