import { SxProps, Theme, Box, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import styles from './Circle1.module.scss';

type CircleProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
    top?: string,
};


const Circle1 = (props: CircleProps) => {
    const { sx, className, top } = props;
    const CircleLayout = styled(Box)(({ theme }) => ({
        ...top && { top: top }
    }));

    return (
        <CircleLayout className={`${styles.Circle1} ${className || ""}`} sx={sx} data-testid="Circle1" data-aos="zoom-in" />
    );
};

export default Circle1;
