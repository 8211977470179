import { SxProps, Theme, Box, styled, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import React, { ReactNode, useEffect, useState } from 'react';
import { newGuid } from '../../utils';
import styles from './TextChangingTitle.module.scss';

type TextChangingTitleProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
    variant?: Variant,
    texts: string[],
};

const TextChangingTitleLayout = styled(Box)(({ theme }) => ({

}));

const TextChangingTitle = (props: TextChangingTitleProps) => {
    const { sx, className, variant, texts } = props;
    const [text, setText] = useState(texts[0]);

    useEffect(() => {
        let index = 0;
        setInterval(() => {
            if (texts.length - 1 < index + 1) {
                setText(texts[0]);
                index = 0;
            }
            else {
                index = index + 1;
                setText(texts[index]);
            }
        }, 3000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <TextChangingTitleLayout key={newGuid()} className={`${styles.TextChangingTitle} ${className || ""}`} sx={sx} data-testid="TextChangingTitle" >
            <Typography variant={variant} fontWeight={600}>{text}</Typography>
        </TextChangingTitleLayout>
    );
};

export default TextChangingTitle;
