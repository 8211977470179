import { ArrowDownward } from '@mui/icons-material';
import { SxProps, Theme, Box, styled, Container, IconButton, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import Subtitle from '../../../components/Subtitle/Subtitle';
import { scrollToId } from '../../../utils';
import styles from './Technologies.module.scss';
import { ReactComponent as MaterialDesign } from '../../../assets/images/materialdesign.svg';
import { ReactComponent as Dynamo } from '../../../assets/images/dynamo.svg';
import { ReactComponent as Forge } from '../../../assets/images/forge.svg';
import { ReactComponent as Revit } from '../../../assets/images/revit.svg';
import { ReactComponent as ReactJS } from '../../../assets/images/reactjs.svg';
import { ReactComponent as NetCore } from '../../../assets/images/netcore.svg';
import { ReactComponent as Azure } from '../../../assets/images/azure.svg';
import Circle3 from '../../../components/Circle3/Circle3';
import Circle4 from '../../../components/Circle4/Circle4';
import Circle5 from '../../../components/Circle5/Circle5';
import Circle6 from '../../../components/Circle6/Circle6';

const techologies = [
    {
        icon: <Revit style={{ height: "67px" }} />,
        text: "Revit API"
    },
    {
        icon: <Dynamo style={{ height: "67px" }} />,
        text: "Dynamo"
    },
    {
        icon: <Forge style={{ height: "67px" }} />,
        text: "Autodesk Forge"
    },
    {
        icon: <ReactJS />,
        text: "React"
    },
    {
        icon: <MaterialDesign style={{ height: "67px" }} />,
        text: "Material Design"
    },
    {
        icon: <NetCore style={{ height: "67px" }} />,
        text: ".NET Core"
    },
    {
        icon: <Azure style={{ height: "67px" }} />,
        text: "Azure"
    }
];

type TechnologiesProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
};

const TechnologiesLayout = styled(Box)(({ theme }) => ({
    paddingTop: 80,
}));

const Technologies = (props: TechnologiesProps) => {
    const { sx, className } = props;
    const showNext = () => scrollToId(document.getElementById('hire'), 450, 'linear');

    return (
        <TechnologiesLayout id='technologies'
            className={`${styles.Technologies} ${className || ""}`}
            sx={sx}
            data-testid="Technologies">
            <Container maxWidth='md' sx={{ position: "relative" }}>
                <Circle3 />
                <Circle4 />
                <Circle5 />
                <Circle6 />
                <Stack gap={2} alignItems="center" paddingBottom={7}>
                    <Subtitle variant='h6' sx={{ padding: 1 }}>Technologies we use</Subtitle>
                    <Typography color="text.secondary" variant='h6' textAlign='center' data-aos="fade-up" data-aos-delay="300">
                        We use only proven, top-notch technologies to grant you security, scalability, and flawless experience.
                    </Typography>
                </Stack>
            </Container>
            <Container>
                <div className={styles.Technologies_List} style={{ "textAlign": "center" }}>
                    {
                        techologies.map(({ icon, text }, index) => {
                            return (
                                <li style={{ display: "flex", flexDirection: "column" }} key={text} data-aos="fade-up" data-aos-delay={100 * index}>
                                    {icon}
                                    {text}
                                </li>
                            );
                        })
                    }
                </div>
            </Container>
        </TechnologiesLayout>
    );
};

export default Technologies;
