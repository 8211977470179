import { SxProps, Theme, Box, styled, debounce, Button, Container, Tabs, Tab } from '@mui/material';
import React, { ReactNode, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { EMAIL, scrollToId } from '../../../utils';
import { Stack } from '@mui/system';

const CustomTabs = styled(Tabs)(({ theme }) => ({
    "& .MuiTabs-indicator": {
        visibility: "hidden"
    }
}));

const CustomTab = styled((props: any) => <Tab {...props} />)(({ theme }) => ({
    textTransform: "none"
}));

type HeaderProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
};

const HeaderLayout = styled(Box)((theme) => ({

}));

const Header = (props: HeaderProps) => {
    const { sx, className } = props;
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const [value, setValue] = useState(-1);

    const navigate = useNavigate();

    const detectMenu = () => {
        const scrollDistance = window.pageYOffset + 90;
        const ids = ["about", "vision", "technologies", "hire"];
        const selected = ids.filter(sectionId => {
            const section = document.getElementById(sectionId);
            if (section) {
                let top = section.offsetTop - 60;
                let bottom = top + section.offsetHeight;
                if (scrollDistance >= top && scrollDistance <= bottom) {
                    return true;
                } else return false;
            }
            return false;
        });
        if (selected.length > 0) {
            setValue(ids.indexOf(selected[0]));
            if (window.history.pushState) {
                window.history.pushState(null, '', `${selected[0]}`);
            } else {
                window.location.hash = selected[0];
            }
        } else setValue(-1);
    };
    const openMenu = () => {
        setMenuIsOpen(!menuIsOpen);
    };

    const scrollHandler = () => {
        window.addEventListener('scroll', event => {
            window.pageYOffset >= 30 ? setIsSticky(true) : setIsSticky(false);
        });
        window.addEventListener('scroll', debounce(() => {
            detectMenu();
        }, 50)
        );
    };

    const goToSection = (to: string) => {
        openMenu();
        if (document.getElementById(to)) {
            window.history.pushState(null, '', `${to}`);
            scrollToId(document.getElementById(to), 300, 'linear');
        }
        else navigate(`/${to}`);

    };

    useState(() => {
        scrollHandler();
    });

    return (
        <HeaderLayout
            data-testid="Header"
            className={`${styles.Header} ${className || ""} ${isSticky && styles.HeaderFixed}`}
            sx={sx} >
            <Box
                id="header"
                color='primary'
                sx={{ padding: 2, backgroundColor: "transparent", position: isSticky ? "sticky" : "unset" }} >
                <Container>
                    <Stack direction="row" alignItems="center">
                        <Logo style={{ "width": "132px", cursor: "pointer" }} onClick={() => navigate("/")} />
                        <Box flex={1} />
                        {/* <IconButton type="button"
                        onClick={openMenu}
                        aria-label="Expand menu">
                        {menuIsOpen ? 'burger is-active' : 'burger'}
                    </IconButton> */}
                        <CustomTabs value={value} sx={{ display: { xs: "none", md: "unset" } }}>
                            <CustomTab label="About" onClick={() => goToSection("about")} />
                            <CustomTab label="Services" onClick={() => goToSection("vision")} />
                            <CustomTab label="Technologies" onClick={() => goToSection("technologies")} />
                            <CustomTab label="Process" onClick={() => goToSection("hire")} />
                        </CustomTabs>
                        <Box flex={1} />
                        <Button
                            color="inherit"
                            variant='outlined'
                            href={`mailto:${EMAIL}`}
                            title="Hire us"
                            sx={(theme) => ({
                                textTransform: "none",
                                fontWeight: "bold",
                                '&:hover': {
                                    color: theme.palette.primary.main
                                }
                            })}
                        >
                            <span>Hire us!</span>
                        </Button>
                    </Stack>
                </Container>
            </Box>
        </HeaderLayout>
    );
};

export default Header;
