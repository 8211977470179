import { ArrowDownward } from '@mui/icons-material';
import { SxProps, Theme, Box, styled, Container, Grid, Stack, Typography, IconButton, useTheme, useMediaQuery } from '@mui/material';
import React, { ReactNode } from 'react';
import Subtitle from '../../../components/Subtitle/Subtitle';
import { scrollToId } from '../../../utils';
import styles from './OurVision.module.scss';
import { ReactComponent as Icon1 } from "../../../assets/images/vision-icon-1.svg";
import { ReactComponent as Icon2 } from "../../../assets/images/vision-icon-2.svg";
import { ReactComponent as Icon3 } from "../../../assets/images/vision-icon-3.svg";
import HeroCircle from '../../../components/HeroCircle/HeroCircle';
import VisionCircle from '../../../components/VisionCircle/VisionCircle';

type OurVisionProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
};

const OurVisionLayout = styled(Box)(({ theme }) => ({
    paddingTop: 80
}));

const OurVision = (props: OurVisionProps) => {
    const { sx, className } = props;
    const theme = useTheme();
    const lessThanMD = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <OurVisionLayout id="vision" className={`${styles.OurVision} ${className || ""}`} sx={sx} data-testid="OurVision">
            <Container maxWidth='md' sx={{ position: "relative" }}>
                <Stack gap={2} alignItems="center" paddingBottom={5}>
                    <VisionCircle />
                    <HeroCircle size={lessThanMD ? "180px" : "360px"} top="-84px" right={lessThanMD ? "-140px" : "-340px"} />
                    <Subtitle variant='h6' sx={{ padding: 1 }}>Our Vision</Subtitle>
                    <Typography variant='h4' textAlign='center' data-aos="fade-up" data-aos-delay="300">
                        We want to equip your business with the latest technologies and seamlessly process
                    </Typography>
                    <Typography color="text.secondary" variant='h6' textAlign='center' data-aos="fade-up" data-aos-delay="600">
                        You are the one who is steering your business. What we provide is safe, solid, and fast technology, as well as trusted staff that will support you on every stage.
                    </Typography>
                </Stack>
            </Container>
            <Container>
                <Grid container>
                    <Grid item sm={12} md={4} padding={2} >
                        <Stack gap={1}>
                            <Subtitle>
                                <Icon1 />
                            </Subtitle>
                            <Typography variant='h6' data-aos="fade-up" data-aos-delay="75">Discovery Phase</Typography>
                            <Typography color="text.secondary" variant='body1' data-aos="fade-up" data-aos-delay="150">That’s where you tell us where you want to go. Together with our best experts on design, development, and project management, you decide on the most efficient solutions that will be tailored to your needs.</Typography>
                        </Stack>
                    </Grid>
                    <Grid item sm={12} md={4} padding={2}>
                        <Stack gap={1}>
                            <Subtitle>
                                <Icon2 />
                            </Subtitle>
                            <Typography variant='h6' data-aos="fade-up" data-aos-delay="375">Product Design</Typography>
                            <Typography color="text.secondary" variant='body1' data-aos="fade-up" data-aos-delay="450">That phase is all about your clients. We define users’ personas and create a user's journey to discover the most engaging design that will attract clients to your brand like a magnet.</Typography>
                        </Stack>
                    </Grid>
                    <Grid item sm={12} md={4} padding={2}>
                        <Stack gap={1}>
                            <Subtitle>
                                <Icon3 />
                            </Subtitle>
                            <Typography variant='h6' data-aos="fade-up" data-aos-delay="675">Software Development</Typography>
                            <Typography color="text.secondary" variant='body1' data-aos="fade-up" data-aos-delay="750">Our main focus is to combine the highest quality code with the lowest technical debt, ensuring the best security and stability. We make sure that your product will be easily scalable in the future.</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </OurVisionLayout>
    );
};

export default OurVision;
