import { SxProps, Theme, Box, styled, Container, Stack, Button, IconButton } from '@mui/material';
import React, { ReactNode } from 'react';
import styles from './Footer.module.scss';
import { ReactComponent as Facebook } from "../../../assets/images/facebook.svg";
import { ReactComponent as Linkedin } from "../../../assets/images/linkedin.svg";
import { Link, useNavigate } from 'react-router-dom';

type FooterProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
};

const FooterLayout = styled(Box)(({ theme }) => ({

}));

const Footer = (props: FooterProps) => {
    const { sx, className } = props;
    const navigate = useNavigate();

    return (
        <FooterLayout className={`${styles.Footer} ${className || ""}`} sx={sx} data-testid="Footer">
            <Container>
                <Stack width="100%" direction={{ xs: "column", md: "row" }} justifyContent="center" alignItems="center">
                    <p className={styles.Footer_Copyright}>
                        &copy; {new Date().getFullYear()} BIM Bros <br />All right reserved.
                    </p>
                    <Box flex={1} />
                    <Stack direction="row" gap={1}>
                        <IconButton>
                            <Facebook />
                        </IconButton>
                        <IconButton>
                            <Linkedin />
                        </IconButton>
                    </Stack>
                    <Box flex={1} />
                    <Button
                        href="/Terms-Of-Use"
                        color="inherit"
                        sx={(theme) => ({
                            textTransform: "none",
                            '&:hover': {
                                color: theme.palette.primary.main
                            }
                        })}>
                        Terms of Use
                    </Button>
                    <Button
                        href='/Privacy'
                        color="inherit"
                        sx={(theme) => ({
                            textTransform: "none",
                            '&:hover': {
                                color: theme.palette.primary.main
                            }
                        })}>Privacy policy</Button>
                </Stack>

            </Container>

        </FooterLayout>
    );
};

export default Footer;
