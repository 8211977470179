import { SxProps, Theme, Box, styled, Container, Grid, Typography, Stack } from '@mui/material';
import React, { ReactNode } from 'react';
import Circle1 from '../../../components/Circle1/Circle1';
import Circle2 from '../../../components/Circle2/Circle2';
import Subtitle from '../../../components/Subtitle/Subtitle';
import styles from './About.module.scss';

type AboutProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
};

const About = (props: AboutProps) => {
    const { sx, className } = props;
    const AboutLayout = styled(Box)(({ theme }) => ({
        paddingTop: 80
    }));

    return (
        <AboutLayout id="about" className={`${styles.About} ${className || ""}`} sx={sx} data-testid="About">
            <Container maxWidth='md' style={{ position: "relative" }}>
                <Circle1 />
                <Circle2 />
                <Stack gap={2} alignItems="start" >
                    <Subtitle variant='h6' sx={{ padding: 1 }}>About Us</Subtitle>
                    <Grid container>
                        <Grid item sm={12} md={6} padding={1}>
                            <Typography variant='h5' data-aos="fade-up" data-aos-delay="300">
                                We provide full-stack development services with deep AEC industry knowledge.
                            </Typography>
                        </Grid>
                        <Grid item sm={12} md={6} padding={1}>
                            <Typography color="text.secondary" variant='h6' data-aos="fade-up" data-aos-delay="600">
                                Our experts will grant efficient design and agile development for long-lasting products so that you can focus on defining further goals. We support you in your marathon to global success.
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
            </Container>
        </AboutLayout>
    );
};

export default About;
