import Moment from 'moment';

export const EMAIL = 'contact@bimbros.com';

export const scrollToId = (destination: any, duration = 200, easing = 'linear') => {
    const easings: any = {
        linear(t: number) {
            return t;
        },
    };

    const start = window.pageYOffset;
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

    const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
    const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
    const destinationOffsetToScroll = ((window as any).width >= 767) ? Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset) : Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset); // on mobile add extra spaces

    if ('requestAnimationFrame' in window === false) {
        window.scroll(0, destinationOffsetToScroll);
        return;
    }

    let lastScrollPosition: any;
    function scroll() {
        let now = 'now' in window.performance ? performance.now() : new Date().getTime();
        let time = Math.min(1, ((now - startTime) / duration));
        let timeFunction = easings[easing](time);

        window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

        if (lastScrollPosition === window.pageYOffset || Math.abs(window.pageYOffset - destinationOffsetToScroll) <= 1) {
            return;
        }

        lastScrollPosition = window.pageYOffset;
        requestAnimationFrame(scroll);
    }

    scroll();
};


export const setCookie = ({ name = null, value = null }: { name: string | null, value: boolean | null; }) => {
    if (!name?.trim() || !value) {
        // eslint-disable-next-line no-console
        console.warn('Provided value or name for cookies cannot be null!');
        return;
    }
    document.cookie = `${name}=${value}`;
};

export const deleteCookie = (name: string) => {
    if (!name.trim()) {
        // eslint-disable-next-line no-console
        console.warn('You have to provide name for cookie to delete!');
        return;
    }
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

export const getCookie = (cookieName: string) => {
    if (!cookieName.trim()) {
        // eslint-disable-next-line no-console
        console.warn('You have to provide name for cookie to delete!');
        return;
    }
    const name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const checkIfCookieExists = (name: string) => {
    if (!name.trim()) {
        // eslint-disable-next-line no-console
        console.warn('You have to provide name for cookie to delete!');
        return;
    }
    const cookieExists = getCookie(name) !== "";
    return cookieExists;
};

export const parallaxCircles1 = [
    {
        start: 0,
        end: 900,
        properties: [
            {
                startValue: 0,
                endValue: 300,
                property: 'translateY',
            },
        ],
    },
];

export const parallaxCircles2 = [
    {
        start: 0,
        end: '.footer',
        properties: [
            {
                startValue: 400,
                endValue: -300,
                property: 'translateY',
            },
        ],
    },
];

export const parallaxRotate = [
    {
        start: '.header',
        end: '.footer',
        properties: [
            {
                startValue: -30,
                endValue: 30,
                unit: 'deg',
                property: 'rotate',
            },
            {
                startValue: -900,
                endValue: 500,
                property: 'translateY',
            },
        ],
    },
];

export const formatBytes = (bytes: number) => {
    var marker = 1024; // Change to 1000 if required
    var decimal = 3; // Change as required
    var kiloBytes = marker; // One Kilobyte is 1024 bytes
    var megaBytes = marker * marker; // One MB is 1024 KB
    var gigaBytes = marker * marker * marker; // One GB is 1024 MB
    var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB

    // return bytes if less than a KB
    if (bytes < kiloBytes) return bytes + " Bytes";
    // return KB if less than a MB
    else if (bytes < megaBytes) return (bytes / kiloBytes).toFixed(decimal) + " KB";
    // return MB if less than a GB
    else if (bytes < gigaBytes) return (bytes / megaBytes).toFixed(decimal) + " MB";
    // return GB if less than a TB
    else if (bytes < teraBytes) return (bytes / gigaBytes).toFixed(decimal) + " GB";
    // return TB
    else return (bytes / teraBytes).toFixed(decimal) + " TB";
};

export const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
};

export const stringAvatar = (name: string) => {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ').length > 1 ? name.split(' ')[1][0] : ""}`,
    };
};

export const getFileExtension = (filename: string) => {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : undefined;
};

export const newGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r | 0x3 | 0x8);
        return v.toString(16);
    });
};

export const toDateTime = (secs: number) => {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return Moment(t).format('MMMM D, YYYY');
};

export const applyMixins = (derivedCtor: any, baseCtors: any[]) => {
    baseCtors.forEach(baseCtor => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
            if (name !== 'constructor') {
                derivedCtor.prototype[name] = baseCtor.prototype[name];
            }
        });
    });
};

export const loadScript = (url: string, onload?: () => void) => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    if (onload) script.onload = onload;
    document.head.appendChild(script);
};

export const getErrorOrDefault = (e: any, message: string) => {
    return e.graphQLErrors[0]?.extensions?.message || message;
};

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, T[]>);

export const htmlToPlainText = (html: string) => {
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<[^>]+>/ig, '');
    return html;
};

