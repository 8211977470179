import { Theme, SxProps, Box } from '@mui/material';
import React, { ReactNode, useEffect } from 'react';
import AppRouting from './App.routing';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './App.module.scss';

type AppProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
};

const App = (props: AppProps) => {
    const { sx, className } = props;

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true
        });
    }, []);

    return (
        <Box className={`${styles.App} ${className || ""}`} sx={sx} data-testid="App">
            <AppRouting />
        </Box>
    );
};

export default App;
