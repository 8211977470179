import { SxProps, Theme, Box, styled } from '@mui/material';
import { SystemProps } from '@mui/system';
import React, { ReactNode } from 'react';
import styles from './HeroCircle.module.scss';

type HeroCircleProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
    top?: string,
    left?: string,
    right?: string,
    bottom?: string,
    size?: string,

};


const HeroCircle = (props: HeroCircleProps) => {
    const { sx, className, size, top, left, right, bottom } = props;
    const HeroCircleLayout = styled(Box)(({ theme }) => ({
        ...top && { top: top },
        ...left && { left: left },
        ...right && { right: right },
        ...bottom && { bottom: bottom },
        ...size && { height: size },
        ...size && { width: size },

    }));

    return (
        <HeroCircleLayout
            className={`${styles.HeroCircle} ${className || ""}`}
            sx={sx}
            data-testid="HeroCircle"
            data-aos="zoom-in" />
    );
};

export default HeroCircle;
