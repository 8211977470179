import { Theme, SxProps, Box, styled, Container, Typography, Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import Circle1 from '../../components/Circle1/Circle1';
import Circle2 from '../../components/Circle2/Circle2';
import Circle3 from '../../components/Circle3/Circle3';
import HeroCircle from '../../components/HeroCircle/HeroCircle';
import Subtitle from '../../components/Subtitle/Subtitle';
import VisionCircle from '../../components/VisionCircle/VisionCircle';
import Footer from '../Index/Footer/Footer';
import GetInTouch from '../Index/GetInTouch/GetInTouch';
import Header from '../Index/Header/Header';
import styles from './Privacy.module.scss';

const CustomListItem = styled(Typography)(({ theme }) => ({
    position: "relative",
    padding: "0 0 0 20px",
    '&:before': {
        content: '""',
        position: "absolute",
        left: "0",
        top: "9px",
        width: "4px",
        height: "4px",
        borderRadius: "50%",
        background: "linear-gradient(#00ebaa, #5e67df)"
    }
}));

type PrivacyProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
};

const Privacy = (props: PrivacyProps) => {
    const { sx, className } = props;
    const theme = useTheme();
    const lessThanMD = useMediaQuery(theme.breakpoints.down('md'));
    const PrivacyLayout = styled(Box)(({ theme }) => ({

    }));

    return (
        <PrivacyLayout className={`${styles.Privacy} ${className || ""}`} sx={sx} data-testid="Privacy">
            <Header />
            <Box height={80} />
            <Container maxWidth="md" sx={{ padding: 2, position: "relative" }}>
                <HeroCircle size={lessThanMD ? "180px" : "360px"} top="50%" right={lessThanMD ? "-140px" : "-340px"} />
                <VisionCircle top="30%" />
                <Circle2 top="25%" />
                <Circle1 />
                <Circle3 />
                <Stack direction="column" paddingTop={5} gap={5}>
                    <Stack textAlign="center" justifyContent="center" gap={2}>
                        <Subtitle>BIM Bros</Subtitle>
                        <Typography variant='h4'>Privacy and Cookie Policy</Typography>
                        <Typography color="text.secondary">This Policy applies to collection of your personal data, as well as to the use of "cookie" files and similar technologies on the bimbros.com website (the “Website”). The Website is operated by BIM Bros Ltd, with its registered office in Ho Chi Minh City, Vietnam.</Typography>
                    </Stack>
                    <Typography variant='h4'>Your Privacy is important to us!</Typography>

                    <CustomListItem>
                        <Typography paddingBottom={1}>Contact data</Typography>
                        <Typography color="text.secondary">In order to make contacting you possible, the Website enables collection of certain personal data: name and surname, your e-mail address. Aside from your personal data, BIM Bros also collects basic statistic data, e.g. location. BIM Bros is the controller of your personal data and takes appropriate steps to ensure security of your data.</Typography>
                    </CustomListItem>


                    <CustomListItem>
                        <Typography paddingBottom={1}>Registration data</Typography>
                        <Typography color="text.secondary">BIM Bros also collects your personal data during the registration process, through the form available at https://www.BIM Bros.com. At registration, we request that you provide us with the following personal data: first name and e-mail.</Typography>
                    </CustomListItem>



                    <CustomListItem color="text.secondary">Other data collected by us through the registration form, which may constitute personal data, are collected and processed on behalf of acquirers, who act as data controllers and entrust us with the processing of personal data for purpose of concluding agreement between you and the acquirers. In such cases, we act as data processors.</CustomListItem>


                    <CustomListItem color="text.secondary">As data controller, we process your data for the purpose of providing our services to you and for general marketing purposes: (i) responding to your query; (ii) sending you tailored offers; and (iii) sending you marketing e-mails and contacting you telephonically, provided that you consented to such forms of communication. Together with marketing information and offers regarding BIM Bros products and services, we may at times approach you with information on compatible products and services, which are offered together with ours.</CustomListItem>


                    <CustomListItem color="text.secondary">In processing your data, we pursue our legitimate interest in responding to your query, concluding contract with the entity you represent and direct marketing of our services (the legal basis for processing of your data).</CustomListItem>


                    <CustomListItem color="text.secondary">Provision of your personal data is voluntary, but if you choose not to provide us with your basic contact information, we may not be able to respond to your query or provide services to the entity you represent.</CustomListItem>


                    <CustomListItem color="text.secondary">Where we act as data controller, you have the right to object to the processing of your data by us and to request that we correct or delete your data. If you file such a request we will stop processing your data and delete it, unless there exists an important overriding legal interest (e.g. defense of claims or existence of a legal obligation to retain the data) which will entitle us to keep your data for a longer period. You also have the right of access to your data, as well as the right to data portability and to restriction of processing.</CustomListItem>


                    <CustomListItem color="text.secondary">We process your personal data only for as long as necessary for the purposes, for which it was collected. In establishing the retention period for your personal data, we take into account, among others, the following criteria: (i) whether you express continuous interest in our services, (ii) if you have become our customer/the entity you represent entered into a contract with us, (iii) if you told us during the call or in a message that you would like us to contact you in the future.</CustomListItem>


                    <CustomListItem color="text.secondary">We entrust the processing of your personal data to entities, which act on our behalf and solely based on our instructions (data processors).</CustomListItem>


                    <CustomListItem color="text.secondary">We may have to share your personal data with competent authorities (in particular the police or prosecution service), if they approach us with such a request, provided that there exists an appropriate legal basis for making your personal data available to them (e.g. a court order).</CustomListItem>


                    <CustomListItem color="text.secondary">If you have any questions with regard to the processing of your data by us or if you wish to exercise your rights, you can contact our data protection contact@bimbros.com. You also have the right to file a complaint to data protection authority</CustomListItem>


                    <Typography variant='h4'>Our use of cookies and similar technologies</Typography>


                    <CustomListItem color="text.secondary">In order to improve the operation of our site, we use cookies, web beacons, device fingerprinting and similar technologies. We also make use of Google Analytics and Hotjar tools.</CustomListItem>


                    <CustomListItem color="text.secondary">Cookies are the small text files / fragments of text that the Website sends to the browser and which are sent back by the browser at the next entrances to the website, for the purpose of keeping the browser session or storing user page data.</CustomListItem>


                    <CustomListItem color="text.secondary">Web beacons (also known as pixel tags) are transparent image files used to monitor your journey around a single interact with the Website and its content.</CustomListItem>


                    <CustomListItem color="text.secondary">We also use Hotjar tool on our Website. Hotjar monitors and stores users' behaviour on the website. Hotjar processes the following data: navigation, website scrolling, cursor movement. Other data such as location, device id, operating system, browser and cookies are also processed.</CustomListItem>


                    <CustomListItem color="text.secondary">Google Analytics is a tool we use in order to calculate visits to our website, check which functionalities of our website are used most and improve the operation of the Website.</CustomListItem>


                    <CustomListItem color="text.secondary">Device fingerprinting is a technology which allows us to collect information about your device (what browser or operating system you use, plug-ins, time zone, other settings). We use this information to identify you and to ensure security of our website.</CustomListItem>


                    <CustomListItem color="text.secondary">Exclusion or limitation of the use of cookies and other similar technologies may affect the functionality of the Website.</CustomListItem>


                    <CustomListItem color="text.secondary">The following types of cookies are used by the Website – ‘session cookies’ which are erased when the user logs out or closes the browser and ‘persistent cookies’ which remain on the user's device for a pre-defined period of time or until the user deletes them manually.</CustomListItem>


                    <CustomListItem color="text.secondary">The Website uses cookies for statistical purposes, to adapt the website content, performance and structure to users’ individual preferences, to provide users with advertising content adapted to their preferences. Cookies and web beacons for ads targeting purposes can be linked to other online services, such as Facebook. Please note that cookies may collect personal information to enhance the site's functionality. Such data is stored in encrypted form and the access to them is limited.</CustomListItem>


                    <CustomListItem color="text.secondary">Cookies that are used by the third parties via the Website, including in particular the users of the website, are subject to their own privacy policy.</CustomListItem>


                    <CustomListItem color="text.secondary">Handling and use of "cookies" is allowed automatically. However, these settings can be changed at any time. For details on how cookies are handled and used, see the settings of your web browser. You can learn more about cookies and similar technologies and check how to disable them for the browser you use by visiting the following website:&nbsp; <a href="http://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">http://www.allaboutcookies.org</a>. </CustomListItem>


                    <CustomListItem color="text.secondary">If you wish to learn more about Google Analytics tools and your privacy, please visit the following site: <a href="https://support.google.com/analytics/answer/6004245?hl=en" target="_blank" rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=en</a>. You can also opt out from Google Analytics by installing a browser add-on available here:&nbsp; <a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=en-GB</a>. </CustomListItem>


                    <CustomListItem color="text.secondary">To learn more about Hotjar and how to opt out, please visit the following websites: <a href="https://www.hotjar.com/privacy" target="_blank" rel="noopener noreferrer">https://www.hotjar.com/privacy</a>&nbsp;and&nbsp; <a href="https://www.hotjar.com/opt-out" target="_blank" rel="noopener noreferrer">https://www.hotjar.com/opt-out</a>. </CustomListItem>
                </Stack>
            </Container>
            <GetInTouch />
            <Footer />
        </PrivacyLayout >
    );
};

export default Privacy;
