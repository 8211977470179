import { SxProps, Theme, Box, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import styles from './Circle4.module.scss';

type Circle4Props = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
};

const Circle4Layout = styled(Box)(({ theme }) => ({

}));

const Circle4 = (props: Circle4Props) => {
    const { sx, className } = props;

    return (
        <Circle4Layout className={`${styles.Circle4} ${className || ""}`} sx={sx} data-testid="Circle4" data-aos="zoom-in" />
    );
};

export default Circle4;
