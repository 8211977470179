import { SxProps, Theme, Box, styled, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import React, { ReactNode } from 'react';
import styles from './Subtitle.module.scss';

type SubtitleProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
    variant?: Variant,
};

const SubtitleLayout = styled(Box)(({ theme }) => ({

}));

const Subtitle = (props: SubtitleProps) => {
    const { sx, className, children, variant } = props;

    return (
        <SubtitleLayout className={`${styles.Subtitle} ${className || ""}`} sx={sx} data-testid="Subtitle" data-aos="">
            <Typography variant={variant}>{children}</Typography>
        </SubtitleLayout>
    );
};

export default Subtitle;
