import { Theme, SxProps, Box, styled, Stack } from '@mui/material';
import React, { ReactNode } from 'react';
import About from './About/About';
import Footer from './Footer/Footer';
import GetInTouch from './GetInTouch/GetInTouch';
import Header from './Header/Header';
import Hero from './Hero/Hero';
import Hire from './Hire/Hire';
import OurVision from './OurVision/OurVision';
import Technologies from './Technologies/Technologies';
import styles from './Index.module.scss';

type IndexProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
};

const IndexLayout = styled(Box)(({ theme }) => ({

}));

const Index = (props: IndexProps) => {
    const { sx, className } = props;

    return (
        <IndexLayout className={`${styles.Index} ${className || ""}`} sx={sx} data-testid="Index">
            <Stack direction="column" sx={{ position: "relative", height: "100%", width: "100%" }}>
                <Header />
                <Hero />
                <About />
                <OurVision />
                <Technologies />
                <Hire />
                <GetInTouch />
                <Footer />
            </Stack>
        </IndexLayout>
    );
};

export default Index;
