import { SxProps, Theme, Box, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import styles from './Circle2.module.scss';

type Circle1Props = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
    top?: string,
};



const Circle2 = (props: Circle1Props) => {
    const { sx, className, top } = props;
    const Circle1Layout = styled(Box)(({ theme }) => ({
        ...top && { top: top }
    }));
    return (
        <Circle1Layout className={`${styles.Circle2} ${className || ""}`} sx={sx} data-testid="Circle2" data-aos="zoom-in" />
    );
};

export default Circle2;
