import { SxProps, Theme, Box, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import styles from './Circle3.module.scss';

type Circle3Props = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
};

const Circle3Layout = styled(Box)(({ theme }) => ({

}));

const Circle3 = (props: Circle3Props) => {
    const { sx, className } = props;

    return (
        <Circle3Layout className={`${styles.Circle3} ${className || ""}`} sx={sx} data-testid="Circle3" data-aos="zoom-in" />
    );
};

export default Circle3;
