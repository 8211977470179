import { ArrowDownward } from '@mui/icons-material';
import { SxProps, Theme, Box, styled, Container, IconButton, Stack, Button, Grid, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import Plx from 'react-plx';
import HeroCircle from '../../../components/HeroCircle/HeroCircle';
import TextChangingTitle from '../../../components/TextChangingTitle/TextChangingTitle';
import { EMAIL, scrollToId } from '../../../utils';
import styles from './Hero.module.scss';

const parallaxData = [
    {
        start: 0,
        end: 500,
        properties: [
            {
                startValue: 0,
                endValue: 200,
                property: 'translateY',
            },
        ],
    },
];

type HeroProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
};

const HeroLayout = styled(Box)(({ theme }) => ({

}));

const Hero = (props: HeroProps) => {
    const { sx, className } = props;
    const showNext = () => scrollToId(document.getElementById('about'), 450, 'linear');
    return (
        <HeroLayout className={`${styles.Hero} ${className || ""}`} sx={sx} data-testid="Hero">
            <Container sx={{ height: "100%", width: "100%", position: "relative" }}>
                <HeroCircle top="100px" left="-145px" size="174px" />
                <Stack direction="row" sx={{ height: "100%", width: "100%" }} justifyContent="center" alignItems="center">
                    <Grid container>
                        <Grid item sm={12} md={6} >
                            <Stack direction="column" height="100%" gap={3} justifyContent="center">
                                <Stack gap={1.5}>
                                    <Typography data-aos="fade-up"
                                        data-aos-duration=".6s"
                                        data-aos-easing="linear"
                                        variant='h4'
                                        fontWeight={600}>We build Cloud-Based</Typography>
                                    <Stack direction="row" data-aos="fade-up" flexWrap="wrap"
                                        data-aos-duration=".6s"
                                        data-aos-delay="200"
                                        data-aos-easing="linear" gap={1.2}>
                                        <TextChangingTitle data-aos="fade-up" variant='h4' texts={["BIM", "Digital Twins", "Automation"]} />
                                        <Typography
                                            variant='h4'
                                            fontWeight={600}>Solutions</Typography>
                                    </Stack>
                                </Stack>
                                <Typography
                                    data-aos="fade-up"
                                    data-aos-duration=".6s"
                                    data-aos-delay="400"
                                    variant='body1'
                                    color="text.secondary">
                                    Let’s create scalable cloud-based BIM Solutions together and give your concept the wings it deserves.
                                </Typography>
                                <Stack direction="row" gap={2}>
                                    <Button
                                        data-aos="fade-up"
                                        data-aos-duration=".6s"
                                        data-aos-delay="600"
                                        variant='contained'
                                        color='secondary'
                                        sx={{ textTransform: "none", fontWeight: "bold" }}
                                        href={`mailto:${EMAIL}`}>
                                        Get in touch
                                    </Button>
                                    <Button
                                        data-aos="fade-up"
                                        data-aos-duration=".6s"
                                        data-aos-delay="800"
                                        variant='outlined'
                                        color='inherit'
                                        sx={(theme) => ({
                                            textTransform: "none",
                                            fontWeight: "bold",
                                            '&:hover': {
                                                color: theme.palette.primary.main
                                            }
                                        })}
                                        onClick={showNext}>Learn more</Button>
                                </Stack>
                            </Stack>

                        </Grid>
                        <Grid item sm={12} md={6} >
                            <div className={styles.Hero_Image}></div>
                        </Grid>
                    </Grid>
                </Stack>
                <Stack
                    data-aos="fade-up"
                    data-aos-duration=".6s"
                    data-aos-delay="1000"
                    visibility={{ xs: "hidden", md: "visible" }}
                    sx={{ position: 'absolute', bottom: 0, left: 0, width: "100%", padding: 5 }} alignItems="center" justifyContent="center">
                    <IconButton
                        sx={(theme) => ({
                            border: "1px solid",
                            '&:hover': {
                                color: theme.palette.primary.main
                            }
                        })}
                        onClick={showNext}>
                        <ArrowDownward />
                    </IconButton>
                </Stack>

            </Container>
        </HeroLayout>
    );
};

export default Hero;
