import { SxProps, Theme, Box, styled, Container, Button, Stack, Grid, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import Circle1 from '../../../components/Circle1/Circle1';
import Circle4 from '../../../components/Circle4/Circle4';
import Circle6 from '../../../components/Circle6/Circle6';
import { EMAIL } from '../../../utils';
import styles from './GetInTouch.module.scss';

type GetInTouchProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
};

const GetInTouch = (props: GetInTouchProps) => {
    const { sx, className } = props;
    const GetInTouchLayout = styled(Box)(({ theme }) => ({
    }));

    return (
        <GetInTouchLayout className={`${styles.GetInTouch} ${className || ""}`} sx={sx} data-testid="GetInTouch">
            <Container maxWidth="md" sx={{ position: "relative" }}>
                <Stack direction="column" >
                    <Circle1 />
                    <Circle4 />
                    <Grid container>
                        <Grid item xs={12} md={8} sx={{ textAlign: "start" }}>
                            <Stack gap={3}>
                                <Typography variant='h4' data-aos="fade-up">
                                    From start-ups to large enterprises
                                </Typography>
                                <Typography variant='h6' data-aos="fade-up" data-aos-delay="200" color="text.secondary">
                                    Tell us about your needs. Together we can build a long-term partnership that will take your business to the bright future.
                                </Typography>
                            </Stack>

                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack justifyContent="end" alignItems="center" height="100%">
                                <Button
                                    data-aos="fade-left"
                                    href={`mailto:${EMAIL}`}
                                    variant="contained"
                                    color="secondary"
                                    sx={{ margin: "20px", textTransform: 'none', fontWeight: "bold" }}>
                                    Get in touch
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
            </Container>
        </GetInTouchLayout>
    );
};

export default GetInTouch;
