import { SxProps, Theme, Box, styled, Container, Stack, Typography, Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import styles from './Hire.module.scss';
import { ReactComponent as Logo } from "../../../assets/images/logo-sign.svg";
import { ReactComponent as Icon1 } from "../../../assets/images/hire-icon-1.svg";
import { ReactComponent as Icon2 } from "../../../assets/images/hire-icon-2.svg";
import { ReactComponent as Icon3 } from "../../../assets/images/hire-icon-3.svg";
import { ReactComponent as Icon4 } from "../../../assets/images/hire-icon-4.svg";
import { ReactComponent as Icon5 } from "../../../assets/images/hire-icon-5.svg";
import { ReactComponent as Icon6 } from "../../../assets/images/hire-icon-6.svg";

const items = [
    {
        icon: <Icon1 />,
        title: 'Planning',
        desc: 'Discovery Phase <br>UX research',
    },
    {
        icon: <Icon2 />,
        title: 'Design',
        desc: 'Wireframing <br>Prototyping',
    },
    {
        icon: <Icon3 />,
        title: 'Development',
        desc: 'Front end <br>Back end',
    },
    {
        icon: <Icon4 />,
        title: 'Testing',
        desc: 'Quality assurance <br>Optimization',
    },
    {
        icon: <Icon5 />,
        title: 'Launch',
        desc: 'Final quality check <br>Product release',
    },
    {
        icon: <Icon6 />,
        title: 'Support',
        desc: 'Ongoing support <br>Maintenance',
    },
];

type HireProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
};

const HireLayout = styled(Box)(({ theme }) => ({
    paddingTop: 80
}));

const Hire = (props: HireProps) => {
    const { sx, className } = props;

    return (
        <HireLayout id='hire' className={`${styles.Hire} ${className || ""}`} sx={sx} data-testid="Hire">
            <Container >
                <Grid container>
                    <Grid item sm={12} md={6} padding={2}>
                        <Stack direction="column" gap={3} justifyContent="center" height="100%">
                            <Typography variant='h4' data-aos="fade-up">
                                Hire a team of experienced engineers for your project
                            </Typography>
                            <Typography data-aos="fade-up" data-aos-delay="150" variant='h6' color="text.secondary">
                                We aim high while paying attention to the smallest details. We work in precisely planned process to scope, design, develop, and launch a scalable software that will help you conquer the markets.
                            </Typography>
                            <ul className={styles.G_List}>
                                <li data-aos="fade-up" data-aos-delay="200">Transparent, iterative process</li>
                                <li data-aos="fade-up" data-aos-delay="300">Quality-driven approach</li>
                                <li data-aos="fade-up" data-aos-delay="400">Agile work management</li>
                                <li data-aos="fade-up" data-aos-delay="500">Focus on partnership</li>
                                <li data-aos="fade-up" data-aos-delay="600">User-centered attitude</li>
                            </ul>
                        </Stack>

                    </Grid>
                    <Grid
                        item
                        sm={12}
                        md={6}
                        padding={2}
                        sx={{ position: "relative" }}
                        minHeight="650px"
                        minWidth={{ xs: "100%", md: "unset" }}>
                        <div className={styles.Hire_Circle} data-aos="">
                            <div className={styles.Hire_Circle_Inner} data-aos="">
                                <Logo style={{ "width": "75px", "height": "72px" }} />
                            </div>
                        </div>
                        <ul className={styles.Hire_List} data-aos="">
                            {
                                items.map(({ icon, title, desc }) => {
                                    return (
                                        <li key={title} data-aos="">
                                            {icon}
                                            <p className={styles.Hire_List_Title}>{title}</p>
                                            <p className={styles.Hire_List_Desc} dangerouslySetInnerHTML={{ __html: desc }} />
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </Grid>
                </Grid>
            </Container>
        </HireLayout>
    );
};

export default Hire;
