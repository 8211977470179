import { SxProps, Theme, Box, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import styles from './VisionCircle.module.scss';

type VisionCircleProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
    top?: string,
};



const VisionCircle = (props: VisionCircleProps) => {
    const { sx, className, top } = props;
    const VisionCircleLayout = styled(Box)(({ theme }) => ({
        ...top && { top: top }
    }));
    return (
        <VisionCircleLayout className={`${styles.VisionCircle} ${className || ""}`} sx={sx} data-testid="VisionCircle" data-aos="zoom-in" />
    );
};

export default VisionCircle;
