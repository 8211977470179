import React, { ReactElement, ReactNode } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Index from "./pages/Index/Index";
import Privacy from "./pages/Privacy/Privacy";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import { newGuid } from "./utils";

export type AppRoute = {
    name: string,
    path: string,
    isProtected: boolean,
    element: ReactNode,
    icon?: ReactElement,
    type: RouteType,
};

export enum RouteType {
    None,
    Default,
    Extension
}
const routes: AppRoute[] = [
    {
        name: "/",
        path: "/",
        isProtected: true,
        type: RouteType.Default,
        element: <Index />
    },
    {
        name: "Terms-Of-Use",
        path: "Terms-Of-Use",
        isProtected: true,
        type: RouteType.Default,
        element: <TermsOfUse />
    },
    {
        name: "Privacy",
        path: "Privacy",
        isProtected: true,
        type: RouteType.Default,
        element: <Privacy />
    },
    {
        name: "*",
        path: "*",
        isProtected: true,
        type: RouteType.Extension,
        element: <Navigate replace to="/" />
    }
];

const AppRouting = () => {

    return (
        <Routes>
            {routes.map(route => {
                return (<Route key={newGuid()} path={route.path} element={route.element}></Route>);
            })}
        </Routes>
    );
};
export default AppRouting;
