import { Theme, SxProps, Box, styled, Container, useTheme, useMediaQuery, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import Circle2 from '../../components/Circle2/Circle2';
import HeroCircle from '../../components/HeroCircle/HeroCircle';
import Subtitle from '../../components/Subtitle/Subtitle';
import VisionCircle from '../../components/VisionCircle/VisionCircle';
import Footer from '../Index/Footer/Footer';
import GetInTouch from '../Index/GetInTouch/GetInTouch';
import Header from '../Index/Header/Header';
import styles from './TermsOfUse.module.scss';

const CustomListItem = styled(Typography)(({ theme }) => ({
    position: "relative",
    padding: "0 0 0 20px",
    '&:before': {
        content: '""',
        position: "absolute",
        left: "0",
        top: "9px",
        width: "4px",
        height: "4px",
        borderRadius: "50%",
        background: "linear-gradient(#00ebaa, #5e67df)"
    }
}));

type TermsOfUseProps = {
    children?: ReactNode | undefined,
    sx?: SxProps<Theme>,
    className?: string,
};

const TermsOfUse = (props: TermsOfUseProps) => {
    const { sx, className } = props;
    const theme = useTheme();
    const lessThanMD = useMediaQuery(theme.breakpoints.down('md'));
    const TermsOfUseLayout = styled(Box)(({ theme }) => ({

    }));

    return (
        <TermsOfUseLayout className={`${styles.TermsOfUse} ${className || ""}`} sx={sx} data-testid="TermsOfUse">
            <Header />
            <Box height={80} />
            <Container maxWidth="md" sx={{ padding: 2, position: "relative" }}>
                <HeroCircle size={lessThanMD ? "180px" : "360px"} top="50%" right={lessThanMD ? "-140px" : "-340px"} />
                <VisionCircle />
                <Circle2 top="25%" />
                <Stack direction="column" paddingTop={5} gap={5}>
                    <Stack direction="column" alignItems="center" sx={{ textAlign: "center" }} gap={2}>
                        <Subtitle>BIM Bros</Subtitle>
                        <Typography variant='h4'>Terms Of Use</Typography>
                        <Typography variant='body1' color="text.secondary">
                            This website is provided to you by BIM Bros Ltd. with its seat in Ho Chi Minh City, Vietnam. These Terms of Use apply to bimbros.com site and to its mobile version (the “Website”).
                        </Typography>
                    </Stack>
                    <Stack direction="column" gap={2}>
                        <Typography color="text.secondary">
                            By choosing to use of the Website, you consent to these Terms of Use and agree to be bound by them. The Website is used to provide you with information about our business and services and to enable:
                        </Typography>
                        <CustomListItem color="text.secondary">
                            Contact between BIM Bros and you through forms available on the Website.
                        </CustomListItem>
                        <CustomListItem color="text.secondary">
                            Downloading materials.
                        </CustomListItem>
                        <CustomListItem color="text.secondary">
                            Applying for vacancies.
                        </CustomListItem>
                        <Typography color="text.secondary">
                            The minimum technical requirements to use the Website are as follows:
                        </Typography>
                        <CustomListItem color="text.secondary">
                            Internet connection.
                        </CustomListItem>
                        <CustomListItem color="text.secondary">
                            The most up to date version of one of the following browsers with enabled cookies and JavaScript: Microsoft Internet Explorer, Mozilla Firefox, Opera, Google Chrome, Apple Safari.
                        </CustomListItem>


                        <Typography color="text.secondary">
                            Enabling cookies will help you make the most of your experience using the Website. For more information on cookies, please refer to our Privacy and Cookie Policy.
                        </Typography>

                        <Typography color="text.secondary">
                            You are only authorized to view, copy, print, and distribute (but not modify) the content on the Website, provided that such use is only for noncommercial purposes.
                        </Typography>

                        <Typography color="text.secondary">
                            The Website and its contents are protected by copyrights, trademarks, and other laws of the Socialist Republic of Vietnam, therefore, you are not authorized to copy or use in any form any software or technology embodied or described in this website. You are not allowed to use the Website for unlawful purposes or to provide unlawful content through the Website. Through the Website, you are able to link to other websites which are not under the control of BIM Bros. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
                        </Typography>

                        <Typography color="text.secondary">
                            We do not warrant that the Website will be fully accurate, compatible, secure, error-free, or virus-free. Please note that we will not be liable for any direct, indirect, special, incidental, consequential, or punitive damages or any other damages whatsoever, whether in an action of contract, statute, tort (including, without limitation, negligence), or otherwise, relating to or arising out of the use of the Website, even if we knew, or should have known, of the possibility of such damages. The above is applicable to the fullest extent permitted by law.
                        </Typography>

                        <Typography color="text.secondary">
                            If you wish to file a complaint with regard to the functioning of the Website, you can do that by emailing us at contact@bimbros.com. We will respond to your complaint within 30 working days.
                        </Typography>

                        <Typography color="text.secondary">
                            We may revise these Terms of Use at any time in our sole discretion by posting such revised Terms of Use on the Website. Such revisions shall be effective and binding on you upon posting unless explicitly stated otherwise by us. Your continued use of the Website following changes to these Website Terms of Use constitutes your agreement to the revised Terms of Use.
                        </Typography>

                    </Stack>
                </Stack>
            </Container>
            <GetInTouch />
            <Footer />
        </TermsOfUseLayout>
    );
};

export default TermsOfUse;
